// extracted by mini-css-extract-plugin
export var blocksChangingBgTitlesContainer = "payload-module--blocks-changing-bg-titles-container--343fd";
export var blocksContainer = "payload-module--blocks-container--8b26a";
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--e4282";
export var blocksInRowsWithOneActiveInnerWrapperTwo = "payload-module--blocks-in-rows-with-one-active-inner-wrapper-two--0bcb1";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--44bf0";
export var blocksInRowsWithOneActiveSectionTwo = "payload-module--blocks-in-rows-with-one-active-section-two--c7851";
export var coloredBlocksContainer = "payload-module--colored-blocks-container--f0ae7";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--2b44e";
export var contactButtonTextSection = "payload-module--contact-button-text-section--794b6";
export var contactButtonTextSectionInnerWrapper = "payload-module--contact-button-text-section-inner-wrapper--e0752";
export var customSubtitle = "payload-module--custom-subtitle--0ea31";
export var imageContainer = "payload-module--image-container--1397e";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--367f7";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--78446";
export var opacitySliderVisibleText = "payload-module--opacity-slider-visible-text--37c27";
export var sectionUnderHeaderContentWrapper = "payload-module--section-under-header-content-wrapper--76aa5";
export var singleBlockContainer = "payload-module--single-block-container--524f0";
export var techStackCategoryTitle = "payload-module--tech-stack-category-title--51bc4";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--7aac4";
export var techStackIconsForCategory = "payload-module--tech-stack-icons-for-category--cf74c";
export var techStackRectangleStyles = "payload-module--tech-stack-rectangle-styles--aae0b";
export var techStackSingleCategory = "payload-module--tech-stack-single-category--511c2";
export var techStackSingleColumn = "payload-module--tech-stack-single-column--1b7ac";
export var techStackSubtitleStyles = "payload-module--tech-stack-subtitle-styles--ccddd";
export var techStackTitleStyles = "payload-module--tech-stack-title-styles--f4646";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--83de8";
export var titleBorderWidth = "payload-module--title-border-width--1e427";
export var titlesContainer = "payload-module--titles-container--cc056";